.footer-container {
    margin-top: 20px;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
}

.footer-copyright span {
    vertical-align: middle;
}

@media screen and (max-width: 475px) {
    .footer-item-container {
        width: 150px;
    }
  }