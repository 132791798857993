.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-container p {
    width: 75%;
}

.profile-image {
    border-radius: 50%;
    max-width: 200px;
}

@media screen and (max-width: 475px) {
    .homepage-container p {
        width: unset;
    }
}