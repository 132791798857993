@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/Open_Sans/OpenSans-Regular.ttf');
}

* {
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
}

body {
  background-color: #43493A;
  color: #76B900;
  margin: 0;
  padding: 20px 60px;
}

a, a:visited {
  color: #76B900;
}

@media screen and (max-width: 475px) {
  body {
      padding-left: 30px;
      padding-right: 30px;
  }
}
