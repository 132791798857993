.header-container {
    width: 100%;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 125px;
    width: 125px;
    box-shadow: 0 0 8px 2px rgb(0, 0, 0, .2);
    border-radius: 20px;
}

.navigation {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
}

li {
    padding: 10px;
}

a {
    text-decoration: none;
}

@media screen and (max-width: 475px) {
    .logo {
        height: 65px;
        width: 65px;
    }
}