.project-card {
    box-shadow: 0 0 8px 2px rgb(0 0 0 / 20%);
    border-radius: 20px;
    padding: 20px;
    background-color: #A7AE9C;
    color: #43493A;
}

.project-title {
    text-align: center;
    margin: 10px 0;
}

.project-date {
    text-align: center;
    margin: 0;
    font-style: italic;
}

.project-website, .project-website:visited {
    display: block;
    text-align: center;
    margin: 5px 0;
    color: #008FBD;
}

.project-description {
    width: 95%;
    margin: 0 auto;
    font-size: 14px;
}