.glyph {
    fill: #76B900;
    height: 25px;
    width: 25px;
}

.glyph path {
    fill: #76B900
}

@media screen and (max-width: 475px) {
    .glyph {
        height: 20px;
        width: 20px;
    }
  }